<template>
  <div>
    <div v-if="classes.length">
      <div 
        class="class-link flex items-center justify-between p-2 pr-4 mb-1 rounded-lg cursor-pointer transition hover:bg-gray-100 group"
        :class="{ active: activeItem === c.id || activeItem === index }"
        v-for="(c, index) in classes"
        :key="index"
      >
        <a
          :href="`?classId=${c.id}`"
          class="flex items-center flex-grow"
          :id="c.id"
          ref="classLink"
          @click.prevent="
            activeItem = c.id
            currentClass(c.id)
          "
        >
          <img
            :src="
              c.avatar
                ? `/img/icons_admin/classes_avatar/${c.avatar}`
                : `https://ui-avatars.com/api/?name=${c.name}&color=7F9CF5&background=EBF4FF`
            "
            :alt="c.name"
            class="flex-shrink-0 mr-2 rounded-full w-10 h-10 object-cover object-center"
          />
          <div>
            <h5 class="font-semibold mb-0">{{ c.name }}</h5>
            <h5 class="gray-color font-normal mb-0">{{ c.subject }}</h5>
            <div class="flex items-center gap-2">
              <div class="flex items-center">
                <BaseIcon name="users" viewBox="0 0 24 24" class="fill-gray-800 h-3.5"/>
                <h6 class="font-semibold gray-color m-0 ml-1">
                  {{ c.students ? c.students.length : 0 }}
                </h6>
              </div>
              <div>
                <h6 class="font-semibold gray-color m-0">Grade: {{ c.grade }}</h6>
              </div>
            </div>
          </div>
        </a>
        <div class="action-menu opacity-0 transition group-hover:opacity-100">
          <div
            class="action-menu-list rounded-md overflow-hidden bg-white"
          >
            <button
              type="button"
              class="action-menu-btn"
              @click="deleteClass(c.id)"
              title="Delete"
            >
              <img
                src="/img/icons_admin/cart-pink.svg"
                alt="Action Menu"
                class="action-icon"
              />
            </button>
            <button type="button" class="action-menu-btn" title="Edit">
              <img
                src="/img/icons_admin/pencil-blue.svg"
                alt="Action Menu"
                class="action-icon"
              />
            </button>
          </div>
        </div>
      </div>

    </div>
    <p class="fz-normal gray-color px-2" v-else>
      Your classes list is empty
    </p>
  </div>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
export default {
  name: 'class-list',
  props: {
    classes: {
      type: Array,
    },
  },
  data: () => ({
    activeItem: null,
  }),
  mounted: function () {
    this.activeItem = this.$store.getters.GET_CURRENT_CLASS
      ? this.$store.getters.GET_CURRENT_CLASS[0].id
      : 0
  },
  components: {
    BaseIcon,
  },
  methods: {
    currentClass(id) {
      this.$store.commit('SET_CURRENT_CLASS', id)
    },
    deleteClass(id) {
      this.$confirm({
        title: `Are you sure you want to delete this?`,
        button: {
          no: 'Cancel',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.commit(
              'REBUILD_CLASSES',
              this.$store.getters.GET_CLASSES.filter((item) => item.id !== id),
            )
            if (this.$store.getters.GET_CLASSES.length) {
              this.$store.commit('SET_CURRENT_CLASS', this.$store.getters.GET_CLASSES[0].id)
              this.activeItem = this.$store.getters.GET_CLASSES[0].id;
            }else{
              this.$store.commit('SET_CURRENT_CLASS', null)
            }
          }
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
