<template>
  <div
    class="flex items-center justify-between -mx-2 px-2 py-2 rounded-3xl relative link-with-icon link-with-delete"
  >
    <a href="#" class="flex items-center truncate pr-3">
      <img
        :src="`https://randomfox.ca/images/${id}.jpg`"
        alt=""
        class="flex-shrink-0 mr-2 rounded-full"
      />
      <h5 class="mb-0 fw-semibold truncate">{{ name }}</h5>
    </a>
    <button
      class="border-0 p-0 inline-flex delete-link flex-shrink-0"
      v-if="withDelete"
      @click.prevent="removeItem(id)"
    >
      <img src="/img/icons/cart.svg" />
    </button>
  </div>
</template>

<script>
export default {
  props: ['id', 'name', 'withDelete'],
  methods: {
    removeItem(id) {
      this.$emit('remove', id)
    },
  },
}
</script>
