<template>
  <div>
    <div v-if="students.length">
      <user-link
        v-for="student in students"
        :key="student.id"
        :id="student.id"
        :name="student.name"
        :withDelete="true"
        @remove="remove"
      />
    </div>
    <p class="fz-normal gray-color" v-else>
      Students list is empty
    </p>
    <v-modal name="add-students" :draggable="true" template="add-students" />
  </div>
</template>

<script>
import userLink from '@/components/user/UserLink'
export default {
  name: 'students-list',
  props: {
    students: {
      type: Array,
    },
  },
  methods: {
    remove(id) {
      this.$store.commit(
        'SET_TMP_STUDENTS_ID',
        this.$store.getters.GET_TMP_STUDENTS_ID.filter((item) => item !== id),
      )
    },
  },
  components: {
    userLink,
  },
}
</script>

<style lang="scss" scoped></style>
