<template>
  <div>
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-4">
        <!-- Students List -->
        <card
          :title="true"
          :titleClass="'mb-4'"
          :bodyClass="'pr-0'"
          v-if="this.$route.name == 'create-class'"
        >
          <template v-slot:title>
            <h4 class="mb-0">My students</h4>
            <a
              href="#"
              class="fz-normal mb-0 outline-none"
              @click.prevent="$modal.show('add-students')"
            >
              Add students
            </a>
          </template>
          <students-list :students="students" />
        </card>
        <!-- Class List -->
        <card
          :title="true"
          titleClass="mb-4 px-2"
          bodyClass="class-card-body"
          v-else
        >
          <template v-slot:title>
            <h4 class="mb-0">My classes</h4>
            <router-link
              :to="{ name: 'create-class' }"
              class="fz-normal mb-0 outline-none"
            >
              Add class
            </router-link>
          </template>
          <class-list :classes="classes" />
        </card>
      </div>
      <!--! col-span-4 -->

      <div class="col-span-8">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import StudentsList from '@/components/classroom/StudentsList'
import ClassList from '@/components/classroom/ClassList'
export default {
  data: () => ({}),
  mounted: function () {},
  computed: {
    students() {
      return this.$store.getters.GET_STUDENTS.filter((item) =>
        this.$store.getters.GET_TMP_STUDENTS_ID.includes(item.id),
      )
    },
    classes() {
      return this.$store.getters.GET_CLASSES
    },
  },
  methods: {},
  components: {
    StudentsList,
    ClassList,
  },
}
</script>
<style lang="scss" scope>
.class-card-body {
  padding: 1rem 0.5rem;
}
.class-link {
  &.active {
    @apply bg-gray-100;
  }
}
</style>
